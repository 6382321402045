import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="mongodb"
      title="MongoDB Backup Service"
      description="Automate your MongoDB Backups and store them on any cloud provider. Support Incremental backup, flexible scheduling, Managed database (DBaaS) or self-hosted database."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automate & trust<br/>your <span className="text-primary">MongoDB backups</span></>)}
      subTitle={(
        <>Create automatic MongoDB backups without code.<br/>
          Set a schedule, a storage and let it work in peace.
        </>)}
      checkMarks={['Self-hosted MongoDB', 'DbaaS & Managed MongoDB']}
      icons={[
        <Ui.Image filename="brands/icons/mongodb-ico.png" alt="MongoDB backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/digitalocean-ico.png" alt="DigitalOcean Managed MongoDB backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/scalegrid-ico.png" alt="Scalegrid MongoDB backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/google-cloud-ico.png" alt="Google Cloud MongoDB Atlas backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/aws-ico.png" alt="AWS MongoDB Altas backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/azure-ico.png" alt="Azure Cosmos DB backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/more-ico.png" alt="DB backup" className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your MongoDB Backup" urlAtrr={{ sb_source: "website", sb_term: "mongodb-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-mongodb-backup-hero.png" alt="SimpleBackups Mongodb backup dashboard: all your MongoDB backups in one place"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Optimize your backups",
                title: (<>Otpimized backups by <span className="text-primary">MongoDB experts</span></>),
                description: (
                  <>
                    <p>
                      Run your backups using optimized backups scripts built
                      by experts and with the right flags for you.
                      Stop messing around with complex documentation.
                      <Ui.FeatureChecks items={['Support large collections', 'MongoDB custom flags', 'Backup Streaming', 'Serverless backup']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-backup-expert.png'
                  alt="MongoDB database backup"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust({subject: 'Mongo databases', keyRef: 'mongodb'}),
              Content_FeatureControl({subject: 'MongoDB', keyRef: 'mongodb'}),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Want to geek out on the technical details?</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">SimpleBackups offers a user-friendly setup process, perfect for those who may not be tech experts. For the tech-savvy, we're eager to dive into the details, tailoring SimpleBackups to meet your unique requirements and maximize its potential for you.</p>
            <p className="py-5 text-lg font-bold">Hosted locally? Using a MongoDB DBaaS service? Not a problem!</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium">
              <li><i className="far fa-cloud text-green mr-2"></i>AWS MongoDB Atlas</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Azure MongoDB Atlas</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Google Cloud MongoDB Atlas</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Azure Cosmos DB</li>
              <li><i className="far fa-cloud text-green mr-2"></i>ScaleGrid Managed MongoDB</li>
              <li><i className="far fa-cloud text-green mr-2"></i>DigitalOcean Managed MongoDB</li>
            </ul>

            <p className="mt-12 py-5 text-lg font-bold">Full-featured MongoDB Backup service, you’re covered:</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium max-w-7xl">
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Point in time Backup restore</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Advanced MongoDB flag management</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup all databases at once</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">SSL Support</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Self-hosted connections credentials</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup streaming</span>
              </li>
            </ul>
            <div className="my-16 text-center"><Ui.Link arrow="right" to="/features">View all features</Ui.Link></div>

            <Ui.Typography tag="h3" className="text-3xl text-center mt-24 mb-8">Wait... there's even more...</Ui.Typography>
            <Sections.FeatureSlider tags={["mongodb"]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about MongoDB Backups?</>}
      text="Find out more and get started with our MongoDB Backup guides"
      tags={['mongodb']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_mongodb_backup_service', 'how_mongodb','how_restore_backup', 'can_download_backup', 'use_for_client_2', 'is_secure', 'use_for_other_things']}/>


  </Layout.Layout>
)

export default ContentPage
